<template>
  <div>
    <BingDunDun></BingDunDun>
  </div>
</template>
<script>
import BingDunDun from './views/Home.vue'
export default {
  components: {
    BingDunDun,
  },
}
</script>
